<template>
  <div class="LayoutLogin">
    <main id="main" class="LayoutLogin__main">
      <a id="top"></a>
      <slot/>
    </main>
  </div>
</template>

<script>

export default {
  name: "LayoutLogin",
}
</script>

<style lang="scss">
.LayoutLogin {
    background-image: url('~@/assets/images/background-lk.png');
    background-color: $lk_grey_12;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  &__main {
    margin-right: auto;
    margin-left: auto;
    max-width: $max-width;
    padding-top: 20px;
  }
}
</style>
