<template>
  <div class="LayoutDefault" :class="$store.getters['misc/getDarkMode'] ? 'theme-dark' : 'theme-light'">
    <Header class="LayoutDefault__header d-md-none">
    </Header>
    <a id="top"></a>
    <main id="main" class="LayoutDefault__main">
      <slot/>
    </main>
    <Nav class="LayoutDefault__nav">

    </Nav>
  </div>
</template>

<script>
import Nav from "@/components/Nav"
import Header from "@/components/Header"

export default {
  name: "LayoutDefault",
  components: {
    Header,
    Nav,
  },
 }
</script>

<style lang="scss">

.LayoutDefault {
  display: grid;
  grid-template-areas:
      "header"
      "content"
      "navig";
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 60px;
  height:100vh;
  width:100vw;

  &.theme-light{
    background-color: $body-bg;
  }
  &.theme-dark{
    background-color: $lk_grey_5;
  }

  &__header {
    grid-area: header;
  }

  &__main {
    overflow-y: scroll;
    grid-area: content;
  }

  &__nav {
    height:60px;
    grid-area: navig;
  }

  ::-webkit-scrollbar{
    height:4px;
    width:4px;
  }

  ::-webkit-scrollbar-thumb{
    background: #CCC;
    width:4px;
    border-radius:2px;
  }

}

//@media only screen and (min-width: 768px) {
@include media-breakpoint-up(md) {
  .LayoutDefault{
    display: grid;
    grid-template-columns: 90px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "navig content";

    &__header {
      height:90px;
    }

    &__nav {
      height:100%;

    }
    &__main {

    }


  }
}
</style>
