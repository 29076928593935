import Vue from 'vue'
Vue.prototype.$appVersion = '1.1.6'

// components (not pages) PAges are in router
import App from './App.vue'

//router
import {router} from './router'

//Vuex store
import store from './store'

import '@/mixins/filters.js';

// axios
import VueAxios from 'vue-axios'
import axios from './plugins/axios-ext';
Vue.use(VueAxios, axios)

// bootstrap
import '@/assets/_custom.scss'
import { BootstrapVue, IconsPlugin, BVConfigPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BVConfigPlugin, {
  BButton: { variant: 'primary' }
})

// Mixins
import toast from './mixins/toast'
Vue.mixin(toast)

// scroll to
const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

// clickOutside
import vOutsideEvents from 'vue-outside-events'
Vue.use(vOutsideEvents)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker)


//Currency input
import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  /* see config reference */
  globalOptions: {
    locale: 'nl',
    currency: null,
    valueAsInteger: true,
    distractionFree: true,
    precision: 2,
    autoDecimalMode: true,
    allowNegative: true
     }
}
Vue.use(VueCurrencyInput, pluginOptions)

// moment
import VueMoment from 'vue-moment'

import i18n from './i18n'
const moment = require('moment')
require('moment/locale/nl')
moment.defineLocale('nl-jr', {
  parentLocale: 'nl',
  weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
})
Vue.use(VueMoment, {moment})

import VueScreen from 'vue-screen';
Vue.use(VueScreen, 'bootstrap');

// Vue.config.errorHandler = (err, vm, info) => {
//   const m = {
//     error: err.toString(),
//     component: (vm.$options && vm.$options._componentTag ? vm.$options._componentTag : 'Unknown'),
//     info: info.toString()
//   }
//   sendLog('err', m)
// };

// eslint-disable-next-line no-unused-vars
// window.onerror = function(message, source, lineno, colno, error) {
//   const m = {
//     error,
//     source,
//     message
//   }
//   sendLog('err', m)
// };

Vue.config.productionTip = false

/* eslint-disable no-new */
// eslint-disable-next-line no-unused-vars
const vm = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
  components: {App},
  i18n,
  template: '<App/>'
})

// function sendLog(severity, message){
//   if(process.env.NODE_ENV === 'production'){
//     axios.post('https://api.leisureking.eu/v2l/utils/log', {
//       application: 'fd.leisureking.eu',
//       severity,
//       message
//     })
//       .then(()=>console.log('LKbug recorded!'))
//       .catch((err)=>console.log(`LKbug ERROR: ${err}`))
//   } else {
//     console.error('ERROR catched by LKbug ErrorHandler')
//     console.log(message)
//   }
// }
